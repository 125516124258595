import axios from 'axios'
import React, { useState } from 'react'
import { NavMenu } from './NavMenu'
import { useNavigate } from 'react-router-dom';
// import Select from "react-select";
// import { IAddress, ICity } from '../interfaces/interfaces';

const RegistrationComp = () => {

    const navigate = useNavigate();
    // const [city, setCity] = useState<ICity[]>([]);
    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        comName: "",
        cityName: "",
        tgName: "",
        socialMediaLink: ""
    })

    const sendData = async () => {
        try{
          await axios.post("https://vaporstlgrm.biz.ua/vapors_node", {...formData})
          navigate("/response", {
            state: { title: "", desc: ", Запит було надіслано", userName: "Гість" }
          })
        }
        catch(e){
            navigate("/response", {
                state: {
                 title: "Exception!",
                   desc: ", вибачайте,  трапилась помилка",
                   userName: "Гість"
                } 
               })
        }
    }

    const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        sendData()
        
    }

    // const numCheck = (e : React.FocusEvent<HTMLInputElement>) => {
    //     if(e.target.name === "phoneNumber" && e.target.value === "") {
    //       e.target.value = "+380";
    //     }
    //   };
    
      const formatPhoneNum = (value: string) => {
        if(!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        if(phoneNumberLength < 3) return `+${phoneNumber}`;
        if(phoneNumberLength < 6) return `+${phoneNumber}`;
        if(phoneNumberLength < 9) {
          return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(
            2,
            5
          )})${phoneNumber.slice(5, 8)}`;
        }
        return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(
          2,
          5
        )})${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;
      };
    
      const changeNum = (e : React.ChangeEvent<HTMLInputElement>) => {
        const formatedPhoneNum = formatPhoneNum(e.target.value);
        setFormData({...formData, phoneNumber: formatedPhoneNum})
      };

    //   const isNull = (data : ICity) => {
    //     if(data) {
    //       setAddress(data.label);
    //       setRef(data.value);
    //       if(data.value !== myStorage.getItem("cityRef")) {
    //         myStorage.setItem("ware", "")
    //         myStorage.setItem("wareRef","")
    //         setCurrPost("")
    //       }
    //       myStorage.setItem("city", data.label)
    //       myStorage.setItem("cityRef", data.value)
    //     } else {
    
    //       setRef("");
    //       setAddress("");
    //       setWarehouses([]);
    //       setPost("");
    //     }
    //   };
    
    // const findCity = (loc: string) => {
    //     axios.post("https://api.novaposhta.ua/v2.0/json/", {
    //       apiKey: "5dd4a730a25dd3e9b7ccf7be6c886564",
    //       modelName: "Address",
    //       calledMethod: "searchSettlements",
    //       methodProperties: {
    //         CityName: loc,
    //         Limit: "50",
    //         Page: "1",
    //       },
    //     })
    //       .then(({data: {data}}) => {
    //         setCity([]);
    //         data[0].Addresses.map(({ Present, Ref }: IAddress) => {
    //           const obj = {
    //             value: Ref,
    //             label: Present,
    //           };
    //           return setCity((prev) => [...prev, obj]);
    //         });
    //       });
    //   };

  return (
    <div>
        <NavMenu userName='Гість' id=''></NavMenu>
        <div className="container">
        <h2 className='mt-5'>Для отримання гуртових цін будь ласка зареєструйтесь заповнивши форму</h2>
        <div className='d-flex justify-content-center row'>
        <form className='d-flex col-11 col-md-6 flex-column' onSubmit={submitForm}>
            <input
                type="text"
                name="fullname"
                className='form-control mt-4' 
                placeholder='ПІБ'
                value={formData.name}
                onChange={(item) => setFormData({...formData, name: item.target.value})}
                required
                />
            <input 
                type="text"
                name="phoneNumber"
                className='form-control mt-4' 
                placeholder='Номер тел'
                value={formData.phoneNumber}
                // pattern="^\+\d{2}\(\d{3}\)\d{3}-\d{4}$"
                minLength={14}
                onChange={(e) => changeNum(e)}
                // onFocus = {(e) => numCheck(e)}
                required
                />
            <input 
                type="text"
                name="comName"
                className='form-control mt-4' 
                placeholder='Нік нейм телеграму @nick'
                value={formData.tgName}
                onChange={(item) => setFormData({...formData, tgName: item.target.value})}
                required
            />
            <input 
                type="text"
                name="cityName"
                className='form-control mt-4' 
                placeholder='Назва міста'
                value={formData.cityName}
                onChange={(item) => setFormData({...formData, cityName: item.target.value})}
                required
                />
                {/* <Select 
                    options={city}
                    isClearable
                    placeholder="Місто"
                    onInputChange={findCity}
                    noOptionsMessage={() => "Назва міста"}
                  /> */}
            <input 
                type="text"
                name="comName"
                className='form-control mt-4' 
                placeholder='Назва компанії'
                value={formData.comName}
                onChange={(item) => setFormData({...formData, comName: item.target.value})}
                required
            />
            <input 
                type="text"
                name="comName"
                className='form-control mt-4' 
                placeholder='Посилання на соціальні мережі фірми (Необов`язкове до заповнення)'
                value={formData.socialMediaLink}
                onChange={(item) => setFormData({...formData, socialMediaLink: item.target.value})}
            />
            <button className="btn btn-primary btn-lg mt-4 mb-5">Відправити</button>
        </form>
        </div>
        <div className='mt-2'>
        <span>Наш менеджер звяжеться з вами в робочий час протягом 30 хв <br/> графік роботи пн- пт 10:00-20:00 <br/> сб 11:00-18:00 <br/> нд - вихідний</span>
        </div>
        
        </div>
    </div>
  )
}

export default RegistrationComp