import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { NavMenu } from "./NavMenu";
import { IAddress, ICity, IData, IDataForRes, ILocation, IUserData, IWarehouse } from "../interfaces/interfaces";
import axios from "axios";

export const Order = () => {

  const location  = useLocation();
  const { fullprice, fullAmount, data, userName, id } = location.state as ILocation; 

  const [formatedData, setFormatedData] = useState<IDataForRes[]>([]);
  const [dataForRes, setDataForRes] = useState<IData[]>([]);
  const [city, setCity] = useState<ICity[]>([]);
  const [ref, setRef] = useState("");
  const [warehouses, setWarehouses] = useState<ICity[]>([]);
  const [address, setAddress] = useState("");
  const [post, setPost] = useState<string>("");
  const [currPost, setCurrPost] = useState("");
  const [isAddressDelivery, setIsAddressDelivery] = useState(false);
  const [addressDelivery, setAddressDelivery] = useState("");
  const [userData, setUserData] = useState<IUserData>({
    num: "",
    name: "",
    price: "",
    text: ""
  });


  const navigate = useNavigate();
  const myStorage = window.localStorage;

  useEffect(() => {
    const res = data.filter(({ amount }: IData) => amount > 0);
    setDataForRes(res);
    res.forEach(({ id, orderPrice, amount }) => {
      const obj = {
        orderQuantity: amount,
        userPrice: orderPrice/amount,
        id: id,
      };
      setFormatedData((prev) => [...prev, obj]);
    });


    const city = myStorage.getItem("city")
    const ware = myStorage.getItem("ware")
    const wareRef = myStorage.getItem("wareRef")
    const cityRef = myStorage.getItem("cityRef")
    if(city && cityRef){
      const post = {
        value: cityRef,
        label: city,
      }
      setCity(() => [post]);
    }
    if(ware && wareRef){
      setCurrPost(ware)
      setRef(wareRef)
    }
  }, []);

  const currencySymbol = (currency: string) => {
    return currency === "USD" ? "$" : "₴"
  }

  const sendData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios.post("https://vaporstlgrm.biz.ua/VPR_UNF/hs/siteListener/getPrice/product", {
        userId: id,
        order: formatedData,
        description: userData.text,
        name: userData.name,
        address,
        post,
        num: userData.num
          .split(/[-+()]+/)
          .slice(2)
          .join(""),
        price: userData.price,
        addressDelivery: isAddressDelivery ? addressDelivery : "",
      })
    .then(() => {
        navigate("/response", {
          state: { title: "", desc: ", Ваше замовлення було надіслано", userName }
        })
    })
    .catch(() => navigate("/response", {
      state: {
       title: "Exception!",
         desc: ", вибачайте,  трапилась помилка",
         userName,
      } 
     }) );
  };

  const findCity = (loc: string) => {
    axios.post("https://api.novaposhta.ua/v2.0/json/", {
      apiKey: "5dd4a730a25dd3e9b7ccf7be6c886564",
      modelName: "Address",
      calledMethod: "searchSettlements",
      methodProperties: {
        CityName: loc,
        Limit: "50",
        Page: "1",
      },
    })
      .then(({data: {data}}) => {
        setCity([]);
        data[0].Addresses.map(({ Present, Ref }: IAddress) => {
          const obj = {
            value: Ref,
            label: Present,
          };
          return setCity((prev) => [...prev, obj]);
        });
      });
  };

  useEffect(() => {
    if(ref) {
    axios.post("https://api.novaposhta.ua/v2.0/json/", {
      apiKey: "5dd4a730a25dd3e9b7ccf7be6c886564",
      modelName: "Address",
      calledMethod: "getWarehouses",
      methodProperties: {
        SettlementRef: ref,
        FindByString: currPost,
        Limit: "50",
        Page: "0",
        Language: "UA",
      },
    })
      .then(({data: {data}}) => {
        setWarehouses([]);
        data.map(({ Description, Number } : IWarehouse) => {
          const obj = {
            value: Number,
            label: Description,
          };
          return setWarehouses((prev) => [...prev, obj]);
        });
      });
    }
  }, [currPost, ref]);

  const isNull = (data : ICity) => {
    if(data) {
      setAddress(data.label);
      setRef(data.value);
      if(data.value !== myStorage.getItem("cityRef")) {
        myStorage.setItem("ware", "")
        myStorage.setItem("wareRef","")
        setCurrPost("")
      }
      myStorage.setItem("city", data.label)
      myStorage.setItem("cityRef", data.value)
    } else {

      setRef("");
      setAddress("");
      setWarehouses([]);
      setPost("");
    }
  };

  const handleInputChange = (inputValue : string) => {
    setCurrPost(inputValue);
  };

  // const numCheck = (e : React.FocusEvent<HTMLInputElement>) => {
  //   if(e.target.name === "phone" && e.target.value === "") {
  //     e.target.value = "+380";
  //   }
  // };

  const formatPhoneNum = (value: string) => {
    if(!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if(phoneNumberLength < 3) return `+${phoneNumber}`;
    if(phoneNumberLength < 6) return `+${phoneNumber}`;
    if(phoneNumberLength < 9) {
      return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(
        2,
        5
      )})${phoneNumber.slice(5, 8)}`;
    }
    return `+${phoneNumber.slice(0, 2)}(${phoneNumber.slice(
      2,
      5
    )})${phoneNumber.slice(5, 8)}-${phoneNumber.slice(8, 12)}`;
  };

  const changeNum = (e : React.ChangeEvent<HTMLInputElement>) => {
    const formatedPhoneNum = formatPhoneNum(e.target.value);
    setUserData({...userData, num: formatedPhoneNum})
  };

  const validData = (price: number) => (price / 100).toFixed(2).replace(".", ",");

  const isWare = (data: ICity) => {
    setPost(data ? data.label : "")
    if(data){
      myStorage.setItem("ware", data.label)
      myStorage.setItem("wareRef", data.value)
    }
    
  }

  useEffect(() => {
    setAddressDelivery("");
    setPost("");

  }, [isAddressDelivery])

  return (
    <div>
      <NavMenu userName={userName} id={id}/>
      <div className="container">
        <table className="table  table-bordered ">
          <thead className="thead-dark align-middle">
            <tr className="text-center">
              <th scope="col">Назва</th>
              <th scope="col">Ціна</th>
              <th scope="col">Кількість</th>
              <th scope="col">Сума</th>
            </tr>
          </thead>
          <tbody>
            {dataForRes.map(({ name, orderPrice, amount, currency}, id) => (
              <tr key={id} className="text-center">
                <td>{name}</td>
                <th scope="row">{validData(orderPrice/amount)}&nbsp;{currencySymbol(currency)}</th>
                <td>{amount}</td>
                <td>{validData(orderPrice)}&nbsp;{currencySymbol(currency)}</td>
              </tr>
            ))}
          </tbody>
          </table>
          <div className='order-cont mb-5'>
            <div className="row">
              <div className="col-6">
                <label htmlFor="Text1" className='d-flex justify-content-center mb-2'>Коментар</label>
                <textarea className="form-control"
                  id="Text1"
                  value={userData.text}
                  onChange={((e) => setUserData({...userData, text: e.target.value}))}
                  />
              </div>
              {
              (fullAmount.grivnya > 0 && fullAmount.dollar > 0) && 
              <>
                <div className="col-3 align-self-center">
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна сума: {validData(fullprice.dollar)}&nbsp;$</span>
                  </div>
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна кількість: {fullAmount.dollar}</span>
                  </div>
                </div>
                <div className="col-3 align-self-center">
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна сума: {validData(fullprice.grivnya)}&nbsp;₴</span>
                  </div>
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна кількість: {fullAmount.grivnya}</span>
                </div>
                </div>
              </>
              }{
                (fullAmount.grivnya > 0 && fullAmount.dollar <= 0) && 
                <div className="col-6 align-self-center">
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна сума: {validData(fullprice?.grivnya)}&nbsp;₴</span>
                  </div>
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна кількість: {fullAmount.grivnya}</span>
                  </div>
                </div>
              }
              {
                (fullAmount.dollar > 0 && fullAmount.dollar <= 0) && 
                <div className="col-6 align-self-center">
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна сума: {validData(fullprice?.dollar)}&nbsp;$</span>
                  </div>
                  <div className='full-res ord'>
                    <span className='order-table'>Загальна кількість: {fullAmount.dollar}</span>
                  </div>
                </div>
              }
              {/* <div className="col-6 align-self-center">
                <div className='full-res ord'>
                  <span className='order-table'>Загальна сума: {fullprice?.dollar}&nbsp;$</span>
                </div>
                <div className='full-res ord'>
                  <span className='order-table'>Загальна кількість: {fullAmount.dollar}</span>
                </div>
                <div className='full-res ord'>
                  <span className='order-table'>Загальна сума в ₴: {fullprice?.grivnya}&nbsp;₴</span>
                </div>
                <div className='full-res ord'>
                  <span className='order-table'>Загальна кількість в ₴: {fullAmount.grivnya}</span>
                </div>
                </div> */}
              </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-11 col-md-6">
                <form onSubmit={(e) => sendData(e)}>
              <span>У випадку, якщо ващі замовлення прямують на різних отримувачей у різні місця, велике прохання, вести усю необхідну інформацію.</span>
              <input className='form-control mt-4'
                  placeholder="Прізвище Ім'я По Батькові"
                  pattern = "^[А-ЩЬЮЯҐЄІЇа-щьюяґєії\s']+(?:\s+[А-ЩЬЮЯҐЄІЇа-щьюяґєії\s']+){2}$"
                  value={userData.name}
                  onChange={(e) => setUserData({...userData, name: e.target.value})}
                  />
              <p className={`mt-2 ${userData.name.match(/^[А-ЩЬЮЯҐЄІЇа-щьюяґєії\s']+(?:\s+[А-ЩЬЮЯҐЄІЇа-щьюяґєії\s']+){2}$/)?.length === 1 ? "text-black" : "text-danger"}`}>Треба вказати Прізвище Ім'я По Батькові саме в такій послідовності і обов'язково українською мовою</p>
              <input 
                  className='form-control mt-3'
                  placeholder='Номер' name='phone'
                  // pattern="^\+\d{2}\(\d{3}\)\d{3}-\d{4}$"
                  minLength={14}
                  value={userData.num}
                  onChange={(e) => changeNum(e)}
                  // onFocus = {(e) => numCheck(e)}
                  />
                  {/* <button>Відділення нової пошти</button><button>Адресна доставка</button> */}
                  <Select 
                    options={city}
                    isClearable
                    placeholder="Місто"
                    onInputChange={findCity}
                    onChange={(data) => isNull((data as ICity))}
                    noOptionsMessage={() => "Назва міста"}
                  />
                  <div className="form-check form-switch mt-3">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={isAddressDelivery} onClick={() => setIsAddressDelivery((value) => !value)}/>
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Адресна доставка</label>
                  </div>
                  {isAddressDelivery ? 
                
                  <input className='form-control mt-4' 
                    placeholder='Адреса'
                    value={addressDelivery} 
                    onChange={(e) => setAddressDelivery(e.target.value)}
                    />
                    : 
                  <Select 
                    options={warehouses}
                    isClearable
                    placeholder="Відділення НП"
                    value={post.length > 0 ? {label: post} : ""}
                    onChange={(data) => isWare((data as ICity))}
                    onInputChange={handleInputChange}
                    noOptionsMessage={() => "Назва відділення"}
                  />}
                  
                  <input className='form-control mt-4' 
                    placeholder='Оціночна вартість'
                    pattern="^[ 0-9]+$" 
                    value={userData.price} 
                    onChange={(e) => setUserData({...userData, price: e.target.value})}
                    />
                    <p className={`mt-3 ${userData.price ? "text-black" : "text-danger"}`}>Якщо поле не заповнене оцінка буде 1 коробка = 500 грн</p>
                  <button className="btn btn-primary btn-lg mt-4 mb-5">Замовити</button>
                </form>
                </div>
              </div>
            </div>
          </div>
  ) 
}
