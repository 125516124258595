import React, { useEffect, useState, FC} from "react";
import Modal from "react-bootstrap/Modal";
import { IPropsRow } from "../interfaces/interfaces";

export const RowComp: FC<IPropsRow> = (props) => {

  const {setPrice, price, name, id, orderPrice, imageUrl, category, quantity, display, userPriceMRPUAH, coefficient, currency, portrait, auth, discounts} = props
  const [amount, setAmount] = useState(0);
  const [visible, setVisible] = useState(false);
  const [activeDiscount, setActiveDiscount] = useState(0);
  const [priceWithOutDiscount, setPriceWithOutDiscount] = useState(0);
  const [show, setShow] = useState(false);

  const currencySymbol = currency === "USD" ? "$": "₴" 

  const elf = "Elf Bar RF350";

  const decrease = () => {
    // if (quantity >= amount && amount > 0) setAmount((prev) => +prev - 1);
    if (amount - coefficient >= 0) setAmount((prev) => +prev - coefficient)
    // amount === 1 && setVisible(false)
  };

  const increase = () => {
    // if (quantity <= 50) {
      if (quantity >= amount + coefficient) {
        setAmount((prev) => +prev + coefficient);
        setVisible(true);
      }
    // } else {
    //   setAmount((prev) => +prev + 1);
    //   setVisible(true);
    // }

    //setAmount((prev) => +prev + 1);
    //setVisible(true);

    // amount === 0 && setVisible(false)
  };

  useEffect(() => {
    if (discounts && discounts.length > 0) {
      let maxDiscount = 0;
      discounts.forEach((el) => {
        if (amount >= el.threshold && el.discount > maxDiscount) {
          maxDiscount = el.discount;
        }
      });
      if (maxDiscount > 0) {
        const discountedPrice = price * amount - (price * amount * maxDiscount / 100);
        setPrice(Number(discountedPrice.toFixed(2)), id, amount); // Фіксуємо кількість знаків після коми
        setPriceWithOutDiscount(price * amount);
        setActiveDiscount(maxDiscount);
      } else {
        setPrice(price * amount, id, amount);
        setPriceWithOutDiscount(0);
        setActiveDiscount(0);
      }
    } else {
      setPrice(price * amount, id, amount);
      setPriceWithOutDiscount(0);
      setActiveDiscount(0);
    }
}, [amount]);


  const validData = (price: number) => (price / 100).toFixed(2).replace(".", ",");

  // const validDataForDiscount = (price: number) => (price / 100).toFixed(3).replace(".", ",");

  const inputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    //quantity <= 50 ? +(e.target.value) <= quantity && +e.target.value >= 0 && setAmount(+e.target.value) : +e.target.value >= 0 && setAmount(+e.target.value)
    if (quantity >= +e.target.value) {
      setAmount(+e.target.value)
    }
    // setAmount(+e.target.value)
}

const blurChange = (e: React.FocusEvent<HTMLInputElement>) => {
    if(e.target.value === "" || +e.target.value <= 0){
      setAmount(0)
      amount !== 0 ? setVisible(true) : setVisible(false)}
      +e.target.value % coefficient !== 0 && setAmount(Math.floor(+e.target.value/coefficient)*coefficient)
}

const priceForOneItemWithDiscount = (price: number, discount: number) => price - (price * discount / 100)

  return (
    <div style={{ display: display ? "block" : "none" }}>
      {/* <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <img
            className="pic-high img-thumbnail img-fluid"
            src={"data:image/png;base64, " + imageUrl}
            alt="error"
          ></img>
        </Modal.Body>
      </Modal> */}
        <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="mod-details">
            <div>Додаткова примітка:</div>
            <div>{portrait}</div>
         </div>
        </Modal.Body>
      </Modal>
      <div className="row pt-4">
        <div className="col-3">
          <div className="cont-img">
            {imageUrl !== "null" ? (
              <img
                // onClick={() => setShow(true)}
                className="pic img-thumbnail img-fluid"
                src={"data:image/png;base64, " + imageUrl}
                alt="error"
              ></img>
            ) : (
              <img
                className="pic img-thumbnail img-fluid"
                src="https://smokejeen.com/images/companies/1/b-logo/Elf-Bar.jpg"
              ></img>
            )}
          </div>
        </div>
        <div className="col-9 align-self-center">
          <div className="description-cont">
            <div className="description">
              <span>{name}</span>
              {/* <span className="bold"> <br/>{portrait}</span> */}
            </div>
            <div className="artiqle">
              <div className="row">
                <div className="col-4">
                  <div className="price">
                    {auth ? (
                      <></>
                    ) : (
                      <><span>{validData(price)} {currencySymbol}/шт</span>
                      {discounts && discounts.length > 0 ? 
                        <div className="mb-3  text-center d-flex flex-column">
                          {discounts.map((el, index) => 
                            <span key={index} className={`text-nowrap ${activeDiscount === el.discount && 'text-danger' }`}>{el.threshold} = {validData(priceForOneItemWithDiscount(price, el.discount))} {currencySymbol}</span>)}
                          </div> : <></>}</>
                    )}
                  </div>
                 
                    {portrait ? (
                      <div className="price details">
                        <span onClick={() => setShow(true)}>Деталі</span>
                      </div>
                    ) : (
                      <></>
                    )}
                
                </div>
                <div className="col-8">
                  <div className="price">
                    {quantity < 100 ? (
                      category === elf ? (
                        <span>{quantity} пачок</span>
                      ) : (
                        <span>{quantity} шт</span>
                      )
                    ) : category === elf ? (
                      <span>100+ пачок</span>
                    ) : (
                      <span>Більше 100+</span>
                    )}
                  </div>
                  {/* <div>МРЦ: {validData(userPriceMRPUAH)}₴</div> */}
                  {/* {auth ? (
                      <></>
                    ) : (
                      <div>МРЦ: {validData(userPriceMRPUAH)}₴</div>
                    )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row border-bottom align-items-center">
        <div className="col-6">
          <div
            className="full-price"
            //fix line 184
            style={{ display: visible ? "flex" : "none", flexDirection: "column"}}
          >
            {priceWithOutDiscount > 0 ? 
          <>
            <span className="small">Ціна {validData(priceWithOutDiscount)}&nbsp;{currencySymbol}</span>
            <span className="text-danger small">Ціна зі знижкою {validData(orderPrice)}&nbsp;{currencySymbol}</span>
          </> :  
          <span>Загальна сума: {validData(orderPrice)} {currencySymbol}</span>}

          </div>
        </div>
        <div className="col-6">
          <form className="d-flex float-end forms">
            <span className="input-group-prepend">
              <button
                type="button"
                className="btn btn-outline-danger m-1 round-mn"
                style={{ display: visible ? "flex" : "none" }}
                data-type="minus"
                data-field="quant[1]"
                onClick={decrease}
              >
                <span className="fa fa-minus">-</span>
              </button>
            </span>
            <input
              type="number"
              className="w-100 text-center m-1 form-control inp"
              max={quantity}
              style={{ display: visible ? "flex" : "none" }}
              value={amount}
              onChange={inputChange}
              onBlur={blurChange}
            />
            <span className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-primary m-1 float-right round-pl mb-3"
                data-type="plus"
                data-field="quant[1]"
                onClick={increase}
                style={{ display: auth ? "none" : "block" }}
              >
                <span className="fa fa-plus">+</span>
              </button>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};
