import React from 'react'
import { IConclusion } from '../interfaces/interfaces'
import { Link } from 'react-router-dom'

const ConclusionMenu = (props: IConclusion) => {
    const {fullVisible, fullprice, fullAmount, data, userName, id, validData, auth} = props
    return (
      auth ?  
      <div
      className="w-100 desc-conc"
      id="conclusion"
      style={{ display: fullVisible || auth ? "flex" : "none" }}
    >
    <div className="container d-flex">
      <div className="col-7">
        <div className="grey d-flex justify-content-center grey as text-center">
        
          <div className="full-res">
            <span>Для отримання гуртових цін будь ласка зареєструйтесь заповнивши форму</span>
          </div>
          </div>
        </div>
      <div className="col-5 grey d-flex justify-content-center">
          <Link
            className="SectionNavigation-Item Section"
            to={"/form"}
            state={{ data, fullprice, fullAmount, userName, id }}
          >
            <button type="button" className="btn btn-success ord"> Заповниити</button>
          </Link>
      </div>
    </div>
  </div>
       :  <div
      className="w-100 desc-conc"
      id="conclusion"
      style={{ display: fullVisible ? "flex" : "none" }}
    >
    <div className="container d-flex">
      <div className="col-7">
        <div className="grey d-flex justify-content-center grey as text-center">
        {(fullAmount.dollar > 0 && fullAmount.grivnya > 0) && 
          <>
           <div className="full-res">
             <span>Сума замовлення: {validData(fullprice.dollar)}&nbsp;$</span>
             </div>
             <div className="full-res">
             <span>Сума замовлення: {validData(fullprice.grivnya)}&nbsp;₴</span>
             </div>
           </>
          }
          {(fullAmount.dollar > 0 && fullAmount.grivnya <= 0) && 
          <div className="full-res">
            <span>Сума замовлення: {validData(fullprice?.dollar)}&nbsp;$</span>
          </div>}
          {(fullAmount.grivnya > 0 && fullAmount.dollar <= 0) && 
          <div className="full-res">
            <span>Сума замовлення: {validData(fullprice?.grivnya)}&nbsp;₴</span>
          </div>
          }
          {/* <div className="full-res">
            <span>Загальна сума товару ціна якого в $: {validData(fullprice?.dollar)}&nbsp;$</span>
          </div> */}
          {/* <div className="full-res">
            <span>Загальна сума товару ціна якого в ₴: {validData(fullprice?.grivnya)}&nbsp;₴</span>
          </div> */}
          </div>
        </div>
      {/* <div className="col-5">
        <div className="grey d-flex justify-content-center grey as text-center">
          <div className="full-res">
            <span>Загальна кількість товару ціна якого в $: {fullAmount.dollar}</span>
            </div>
            <div className="full-res">
            <span>Загальна кількість товару ціна якого в ₴: {fullAmount.grivnya}</span>
            </div>
          </div>
          </div> */}
      <div className="col-5 grey d-flex justify-content-center">
          <Link
            className="SectionNavigation-Item Section"
            to={"/order"}
            state={{ data, fullprice, fullAmount, userName, id }}
          >
            <button type="button" className="btn btn-success ord"> Замовити</button>
          </Link>
      </div>
    </div>
  </div>
   
  )
}

export default ConclusionMenu